import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dropdown } from 'rsuite';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { ArrowRight, profielLogout, profielSettings } from 'res/images';
import { Loader } from 'whealth-core-web/components';
import { profileImage, teamRoles, titlizeString } from 'whealth-core-web/components/Helper.js';
import useApiManager from 'networking/ApiManager';

function AdminPanelHeader(props) {
  const { iconTitle, title, showTree, searchBox, showIcon, data } = props;
  console.log("AdminPanelHeader data: ", data);
  const navigate = useNavigate();
  const location = useLocation();
  const ApiClient = useApiManager();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const isPatient = location.pathname == '/patient'
  const isAppointment = location.pathname == '/appointment'

  let { isLoading } = useGetLoginUserDataQuery();

  //  <span class="material-icons-outlined">grid_view</span>
  //           <span class="material-icons-outlined">person_outline</span>
  //           <span class="material-icons-outlined">description</span>
  //           <span class="material-icons-outlined">people_alt</span>
  //           <span class="material-icons-outlined">settings</span>

  const breadCrumbLink = (item) => {
    item.link && navigate(item.link);
  };

  const renderHeaderTitle = () => {
    if (showTree) {
      return (
        <div className='d-flex breadcrumbprofile'>
          {showTree.map((item, index) => {
            return (
              <div className='d-flex' key={index}>
                {index > 0 && index + 1 <= showTree.length && (
                  <div className='arrow'>
                    <span class='material-icons'>chevron_right</span>
                  </div>
                )}
                <div key={index} className=''>
                  <div onClick={() => breadCrumbLink(item)}>
                    {item?.iconClass ? (
                      <span className='material-icons-outlined breadcrumbpic'>{item?.iconClass}</span>
                    ) : item?.icon ? (
                      <img className='breadcrumbpic' src={item?.icon} />
                    ) : (
                      ''
                    )}
                    <span className='capitalize'>{item?.title}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className='d-flex align-items-center breadcrumbIcon'>
          <span className='material-icons-outlined'>{iconTitle}</span>
          <div>{title}</div>
        </div>
      );
    }
  };

  const currentAdminProfile = () => {
    navigate(`/team/${userData.id}/edit`, {
      state: { userData },
    });
  };

  const handleLogout = () => {
    ApiClient.currentAdminProfile()
      .then((res) => {
        window.webengage.user.logout(res.data.uuid);
        localStorage.clear();
        window.location.href = '/';
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UserAvatar = () => {
    return (
      <div className='userDp profile-dropdown-menu'>
          <Dropdown
            noCaret
            placement= 'bottomEnd'
            title={
              <img
                src={profileImage(userData.gender, userData.profile_url)}
                className='nav-link dropdown-toggle userImage'
                data-bs-toggle='dropdown'
                href='#'
                role='button'
                aria-expanded='false'
              />
            }
          >
            <Dropdown.Item>
              <div className='d-flex  dropdown-profile '>
                <div className='dropdown-profile-pic '>
                  <img
                    src={profileImage(userData.gender, userData.profile_url)}
                    className='nav-link dropdown-toggle userImage'
                    data-bs-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-expanded='false'
                  />
                </div>
                <div className='dropdown-profile-name capitalize'>
                  {userData.full_name}
                  <span className='d-flex'>{teamRoles(userData.role)}</span>
                </div>
              </div>
            </Dropdown.Item>
            {userData.role != 'ee' ? (
              <Dropdown.Item>
                <div onClick={currentAdminProfile} className='profilesub_menu'>
                  <span class='material-icons-outlined'>settings</span>
                  My Profile
                </div>
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item>
              <div
                className='profilesub_menu'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let windoConfirm = window.confirm('Are you sure you want to sign out?');
                  if (windoConfirm) {
                    handleLogout();
                  }
                }}
              >
                <span class='material-icons-outlined'>logout</span>
                Sign Out
              </div>
            </Dropdown.Item>
          </Dropdown>
        </div>
    )
  }

  const AppointmentStats = (data) => {
    console.log('AppointmentStats data: ', data)
    return (
      <div className="d-flex justify-content-between appointment-stats">
        {/* New Consult */}
        <div className="stats-section">
          <div className="stats-title">
            <span className="dot new-consult"></span>
            <span className="title">New Consult</span>
          </div>
          <div className="stats-list">
            <div className='stat-col'>
              <span className="stat-item">Booked</span>
              <span>{data?.data?.consult?.Booked || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Arrived</span>
              <span>{data?.data?.consult?.Arrived || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Show</span>
              <span>{data?.data?.consult?.Show || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">NoShow</span>
              <span>{data?.data?.consult?.Noshow || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Overdue</span>
              <span>{data?.data?.consult?.Overdue || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Total</span>
              <span>{data?.data?.consult?.Total || 0}</span>
            </div>
          </div>
        </div>
  
        {/* Follow-Ups */}
        <div className="stats-section">
          <div className="stats-title">
            <span className="dot follow-up"></span>
            <span className="title">Follow-Ups</span>
          </div>
          <div className="stats-list">
            <div className='stat-col'>
              <span className="stat-item">Booked</span>
              <span>{data?.data?.followup?.Booked || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Arrived</span>
              <span>{data?.data?.followup?.Arrived || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Show</span>
              <span>{data?.data?.followup?.Show || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">NoShow</span>
              <span>{data?.data?.followup?.Noshow || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Overdue</span>
              <span>{data?.data?.followup?.Overdue || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Total</span>
              <span>{data?.data?.followup?.Total || 0}</span>
            </div>
          </div>
        </div>
  
        {/* Procedure */}
        <div className="stats-section">
          <div className="stats-title">
            <span className="dot procedure"></span>
            <span className="title">Procedure</span>
          </div>
          <div className="stats-list">
            <div className='stat-col'>
              <span className="stat-item">Booked</span>
              <span>{data?.data?.procedure?.Booked || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Arrived</span>
              <span>{data?.data?.procedure?.Arrived || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Show</span>
              <span>{data?.data?.procedure?.Show || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">NoShow</span>
              <span>{data?.data?.procedure?.Noshow || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Overdue</span>
              <span>{data?.data?.procedure?.Overdue || 0}</span>
            </div>
            <div className='stat-col'>
              <span className="stat-item">Total</span>
              <span>{data?.data?.procedure?.Total || 0}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  

  return (
    <div style={isPatient ? { width: '32%' } : {}} className='d-flex  align-items-center  justify-content-between header-main'>
      {!isPatient && <div>{renderHeaderTitle()}</div>}
      {
        isPatient ? (
            <div className='d-flex align-items-center w-100 header-left-part gap-16'>
              {searchBox}
            </div>
        ) : (
          <div className='d-flex align-items-center w-40 header-right-part gap-16'>
            {searchBox}
            {/* <UserAvatar /> */}
            {isAppointment && <AppointmentStats data={data} />}
          </div>
        )
      }
      <Loader show={isLoading} />
    </div>
  );
}

export default AdminPanelHeader;
