import React from 'react';
import { carePlan } from 'res/images';
import CommonBodyCard from 'whealth-core-web/components/CommonBodyCard';
import { dateFormat, titlizeString } from 'whealth-core-web/components/Helper';

function CarePathway(props) {
    const care_pathway = props?.data?.health_matrix?.care_pathway || ""; // Ensure it's a string
    const medicationTaken = props?.data?.medications_taken || [];        // Ensure it's an array
    
    // Convert care_pathway to a comma-separated string
    const carePathwayString = care_pathway.replace(/\|/g, ", ");
    
    // Convert medicationTaken to a comma-separated string
    const medicationTakenString = medicationTaken.join(", ");
    
    // Calculate the difference (case-insensitive)
    const difference = care_pathway
      .split("|")
      .filter(item => !medicationTaken.some(med => med.toLowerCase() === item.toLowerCase()))
      .join(", ");
    
    return (
      <div className='d-md-flex mb-3 bodyCard'>
        <div className='card w-100 no-border'>
          <div className='card-body'>
            <div className='border-bottom pb-2 mb-2'>
              <div className='checkupHead'>Care Pathway</div>
            </div>
            <div className='d-flex checkup-data-part'>
              <div className='checkup-data d-flex flex-column w-100'>
                <CommonBodyCard
                  icon={carePlan}
                  label='Prescribed: '
                  value={carePathwayString || 'N/A'}
                />
                <CommonBodyCard
                  icon={carePlan}
                  label='Current: '
                  value={difference || 'N/A'}
                />
                <CommonBodyCard
                  icon={carePlan}
                  label='Taken: '
                  value={medicationTakenString || 'N/A'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CarePathway;
