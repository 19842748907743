import React, { useEffect, useState } from 'react';
import { CoreDropDown } from 'whealth-core-web/components';
import { Chart } from 'react-google-charts';
import { dateFormatString, prepareGraphData, prepareGraphData1, subtractMonths } from 'whealth-core-web/components/Helper';
import { useParams } from 'react-router-dom';


function PainScore({ title, graphData, showWeight, setWeight, dateValue, setDateValue, getGraphData, goalData }) {
  const [graphDataArr, setGraphDataArr] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    getGraphData();
  }, [dateValue, goalData, id]);

  useEffect(() => {
    setGraphDataArr(prepareGraphData1(graphData?.plot_data?.plot_date, dateValue));
    setWeight(graphData?.plot_data?.average);
  }, [graphData, dateValue]);

  const getChartWidth = () => {
    const [startDate, endDate] = dateValue.split('-');
    const start = new Date(startDate.split('/').reverse().join('-'));
    const end = new Date(endDate.split('/').reverse().join('-'));
    
    const daysDifference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    if (daysDifference <= 31) {
      return '1000px';
    } else if (daysDifference <= 92) {
      return '100%';
    } else if (daysDifference <= 182) {
      return '100%';
    } else {
      return '700px';
    }
  };

  const options = {
    legend: 'none',
    pointSize: 8,
    curveType: 'function',
    vAxis: {
      viewWindow: { min: 0, max: 10 },
      baseline: 0,
      format: '#',
      ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      textStyle: {
        fontSize: 10,
      },
    },
    trendlines: {
      0: {
        type: 'exponential',
        visibleInLegend: true,
        color: 'red',
      },
    },
    chartArea: {
      width: '80%',
      height: '70%',
      left: 50
    }
  };

  const durationData = [
    { title: '1 Year', id: dateFormatString(new Date(subtractMonths(11, new Date())).setDate(1)) },
    { title: '6 Months', id: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)) },
    { title: '3 Months', id: dateFormatString(new Date(subtractMonths(2, new Date())).setDate(1)) },
    { title: '1 Month', id: dateFormatString(subtractMonths(1, new Date())) },
  ];

  return (
    <>
      <div className='d-md-flex mb-3'>
        <div className='card w-100 weight-card no-border'>
          <div className='card-body' style={{ overflowX: 'auto' }}>
            <div className='d-flex'>
              <div className='flex-grow-1'>
                <h5 className='card-title'> {title || 'Pain Graph'}</h5>
                {/* <h6 className='card-title'>{showWeight ? parseFloat(showWeight) + ' ' : 'N/A'}</h6> */}
              </div>
              <div className='ms-auto'>
                <CoreDropDown value={dateValue} setValue={setDateValue} data={durationData} />
              </div>
            </div>
            <div className='d-flex' style={{ width: getChartWidth(), overflowX: 'auto' }}>
              {<Chart chartType='LineChart' data={graphDataArr} options={options} width={getChartWidth()} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PainScore;
