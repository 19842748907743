import React, { useState } from 'react';
import { warn } from 'react-bootstrap-typeahead/types/utils';
import { Button } from 'rsuite';
import { CoreCheckBox, ReusableButton, BorderBtnWithBg, CoreDropDown, CoreButton } from 'whealth-core-web/components';

function DashBoardHeader(props) {
  const {
    data,
    leftData,
    showCheckbox,
    showText,
    patienLeftbtn,
    onClick,
    filterData,
    activePatientBtn,
    activePatientOnclick,
    activePatientBtnChange,
  } = props;

  const [inputData, setInputData] = useState({ value: '' });
  const handleInputChange = (val) => {
    inputData.value = val;
    setInputData({ ...inputData });
  };

  const renderButtons = () => {
    if (data) {
      return data.map((item, index) => {
        return (
          <div key={index}>
            <ReusableButton
              onClick={item.onClick}
              icon={item.icon}
              outline={item.outline}
              title={item.title}
              active={!!item.status}
              style={item.style}
            />
          </div>
        );
      });
    } else {
      return;
    }
  };

  const renderBtn = () => {
    return !filterData.pmsConsultDate ? (
      <Button className='m-0 patientBtnNew' onClick={() => onClick()}>
        Today's Appointments
      </Button>
    ) : (
      <ReusableButton onClick={() => onClick()} title={`Today's Appointments`} active />
    );
  };

  console.warn('activePatientBtnChange', activePatientBtnChange);

  const activePatient = () => {
    return !filterData.active_patients ? (
      <div className='ms-2 d-flex '>
        <Button className='m-0 patientBtnNew' onClick={() => activePatientOnclick()} style={{ fontSize: '9px' }}>
          Active patients
        </Button>
      </div>
    ) : (
      <div className='ms-2 d-flex '>
        <ReusableButton onClick={() => activePatientOnclick()} title={`Active patients`} active style={{ fontSize: '9px' }} />
      </div>
    );
  };

  const leftDataMap = () => {
    return leftData.map((item, index) => {
      if (item.hide) {
        return;
      }
      if (item.showBtn) {
        return (
          <div className='d-flex'>
            <div className='me-2'>
              <BorderBtnWithBg
                onClick={item.onClick}
                className={item.className}
                key={index}
                iconImg={item.icon}
                title={item.title}
              />
            </div>
            {/* {patienLeftbtn && renderBtn()} */}
            {/* {activePatientBtn && activePatient()} */}
          </div>
        );
      } else {
        return (
          <div key={index} className='headingTitleLink' style={item.style}>
            {item.title}
          </div>
        );
      }
    });
  };

  return (
    <div className='d-flex align-items-center justify-content-between main-right-part-header'>
      <div className='d-flex applyFilterBtn gap-15'>
        {showCheckbox && <CoreCheckBox />}
        {leftDataMap()}
      </div>
      <div className='d-flex align-items-center gap-16'>
        {showText && <div className='headerTextLite'>{showText()}</div>}
        {renderButtons()}
      </div>
      {activePatientBtn && activePatient()}
    </div>
  );
}

export default DashBoardHeader;
