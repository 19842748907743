import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { add, upload, checkmarkSm, starSmall } from 'res/images';
import { Button, Table } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

const AddTimelineTab = (props) => {
  const ApiClient = useApiManager();
  const { patientProfileData, setStatus, newGoal, newPrescription, newConsultation, uploadReport, handleAddDepartmentClick } = props;
  const [isNivanCare, setIsNivanCare] = useState(false);
  const [openDeptModal, setOpenDeptModal] = useState(false);

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'departments_clinics' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
      });
    }
  }, [configData]);

  return (
    <>
      <div className='d-flex'>
        <div className='pt-3'>
          {
            patientProfileData?.appointment_attendance_status && (
              <button type='button' className='btn mt-3 btn-outline-primary' onClick={newPrescription}>
                <img src={add} className='icon' /> New Prescription
              </button>
            )
          }
          <button type='button' className='btn mt-3 btn-outline-primary' onClick={newGoal}>
            <img src={add} className='icon' /> New Routine (Goal)
          </button>
          <button onClick={() => setStatus('notes')} type='button' className='btn mt-3 btn-outline-primary'>
            <img src={add} className='icon' /> Note
          </button>
          <button onClick={() => setStatus('task')} type='button' className='btn mt-3 btn-outline-primary'>
            <img src={add} className='icon' /> Task
          </button>
          <button onClick={uploadReport} type='button' className='btn mt-3 btn-outline-primary'>
            <img src={upload} className='icon' /> Upload Report
          </button>
          {isNivanCare && (
            <button onClick={() => handleAddDepartmentClick()} type='button' className='btn mt-3 btn-outline-primary'>
              <img src={add} className='icon' /> Department
            </button>
          )}
          {configData && configData.findIndex((item) => item.key == 'enable_appointment' && item.isActive) != -1 ? (
            <button type='button' className='btn mt-3 btn-outline-primary' onClick={newConsultation}>
              <img src={add} className='icon' /> Add Appointment
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AddTimelineTab;
