import React from 'react';
import { vitalsIcon1, vitalsIcon2, vitalsIcon3, vitalsIcon4, vitalsIcon5 } from 'res/images';
import CommonBodyCard from 'whealth-core-web/components/CommonBodyCard';
import { dateFormat, titlizeString } from 'whealth-core-web/components/Helper';

function BodyCard(props) {
  const { data, title, items } = props;

  if (!items) {
    return (
      <div className='d-md-flex mb-3 bodyCard'>
        <div className='card w-100 no-border'>
          <div className='card-body'>
            <div className='d-flex justify-content-between border-bottom pb-2 mb-2'>
              <div className='checkupHead'>{title || 'Current Vitals'}</div>
              <div className='light-black checkupTime'>
                Last Update: {dateFormat(data?.health_matrix?.last_activity)}{' '}
              </div>
            </div>
            <div className='d-flex checkup-data-part'>
              <div className='checkup-data d-flex flex-column w-100'>
                <CommonBodyCard
                  icon={vitalsIcon1}
                  label='Current BMI'
                  value={
                    (data?.health_matrix?.current_bmi &&
                      data?.health_matrix.current_bmi + ',' + data.health_matrix.bmi_status) ||
                    'N/A'
                  }
                />
                <CommonBodyCard
                  icon={vitalsIcon2}
                  label='Current Weight'
                  value={
                    data.health_matrix?.current_weight
                      ? parseFloat(data.health_matrix?.current_weight).toFixed(2) + ' kg'
                      : 'N/A'
                  }
                />
                <CommonBodyCard
                  icon={vitalsIcon3}
                  label='Height'
                  value={(data.health_matrix?.current_height && data.health_matrix?.current_height + ' cm') || 'N/A'}
                />

                {data?.health_matrix?.current_sugar_level && (
                  <CommonBodyCard
                    icon={vitalsIcon4}
                    label='Sugar Level'
                    value={data.health_matrix?.current_sugar_level || 'N/A'}
                  />
                )}
                {data?.health_matrix?.current_blood_pressure && (
                  <CommonBodyCard
                    icon={vitalsIcon5}
                    label='Blood Pressure'
                    value={data.health_matrix?.current_blood_pressure || 'N/A'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // const renderDataMap = () => {
    //   return items.map((item, index) => {
    //     return (
    //       <CommonBodyCard
    //         items
    //         icon={item.icon}
    //         label={item.label}
    //         value={(data?.health_matrix && data.health_matrix[item.key] && data.health_matrix[item.key]) || 'N/A'}
    //       />
    //     );
    //   });
    // };
    const renderDataMap = () => {
      return data?.profile_info?.map((item, index) => {
        if (
          item.key == 'pain_site' ||
          item.key == 'reason_for_patient_engagement' ||
          item.key == 'conversion_probability'
        ) {
          return (
            <>
              <CommonBodyCard
                defaultstart='nivaan-items-start'
                marginnone='nivaan_ms-0'
                flexcolumn='nivaan_flex-column'
                items
                icon={<span class='material-icons-outlined'>vaccines</span>}
                label={item.question}
                value={item.answer?.replaceAll('|', ', ') || 'N/A'}
              />
            </>
          );
        }
      });
    };

    return (
      <div className='d-md-flex mb-3 bodyCard'>
        <div className='card w-100 no-border'>
          <div className='card-body'>
            <div className='d-flex justify-content-between border-bottom pb-2 mb-2'>
              <div className='checkupHead'>{title || 'Current Vitals'}</div>
              <div className='light-black checkupTime'>
                Last Update: {dateFormat(data?.health_matrix?.last_activity)}{' '}
              </div>
            </div>
            <div className='d-flex checkup-data-part'>
              <div className='checkup-data d-flex flex-column w-100'>
                {renderDataMap()}
                {/* <CommonBodyCard
                  defaultstart='nivaan-items-start'
                  marginnone='nivaan_ms-0'
                  flexcolumn='nivaan_flex-column'
                  items
                  icon={<span class='material-icons-outlined'>vaccines</span>}
                  label='care pathway'
                  value={
                    data?.health_matrix?.care_pathway ? data?.health_matrix?.care_pathway?.replaceAll('|', ', ') : 'N/A'
                  }
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BodyCard;
