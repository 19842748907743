import React, { useMemo, useState } from 'react';
import './postHeader.css';

function PostHeader(props) {
  const { onClickHandle, data, postLinks, callLog, thisPage } = props;
  const [activeLink, setActiveLink] = useState(callLog ? postLinks[1]?.link : postLinks[0]?.link || '');

  const sortArrayOfObjects = (array, key) => {
    return array.sort((a, b) => {
        if (a[key] < b[key]) {
            return 1;
        } else if (a[key] > b[key]) {
            return -1;
        } else {
            return 0;
        }
    });
  };
  
  const renderPostLinks = () => {
    const sortedPostLinks = sortArrayOfObjects(postLinks, 'count');
    return sortedPostLinks.map((item, index) => {
      let className = 'inActive';
      if (item.link == activeLink) {
        className = 'active';
      } else {
        className = 'inActive';
      }
      if (sortedPostLinks.length == 1) {
        className += ' text-decoration-none';
      }

      return (
        <div
          key={index}
          onClick={() => {
            onClickHandle(item);
            setActiveLink(item.link);
          }}
        >
          <div style={{fontSize: '9px'}} className={`${className} capitalize`}>{item.title.replace(/\s*\(\s*(\d+)\s*\)/, '($1)')}</div>
        </div>
      );
    });
  };

  return <div className={`d-flex ${thisPage==="patient" ? 'postHeaderPatient' : 'postHeader'} headingTitleLink`}>{renderPostLinks()}</div>;
}

export default PostHeader;
